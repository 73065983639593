/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import '../stylesheets/application.css.scss'
// import 'select2/src/scss/core.scss'

// window.$ = window.jQuery = global.$ = global.jQuery = require("jquery")
// import $ from 'jquery';

// require('bootstrap')
require("@rails/ujs").start()
// require("turbolinks").start()
// import {} from 'jquery-ujs'
// require('cocoon')
require('cocoon-js')
// require('toastr')
require('moment')
require('moment/locale/hu.js')
require('bootstrap-fileinput')
require('bootstrap-fileinput/js/locales/hu')
require('./bootstrap-fileinput-theme-la')
// require('blueimp-gallery')
// require('blueimp-load-image')
// require('blueimp-bootstrap-image-gallery/js/bootstrap-image-gallery')
// import 'blueimp-bootstrap-image-gallery/js/bootstrap-image-gallery.js';
// import blueimp from 'exports-loader?window.blueimp!blueimp-gallery'
require('./image-factory-raktaroid.js')
require('./inventories.js')
require('./stored_object.js')
// require('./image-factory-raktaroid.js') // copied into end of this file
// require('bootstrap-daterangepicker')
require('daterangepicker')
require('bootstrap-datepicker')
require('../bootstrap-datepicker/locales/bootstrap-datepicker.hu')
require('bootstrap-datetimepicker/src/js/bootstrap-datetimepicker')
require('ion-rangeslider')
require('select2')
// import AutoNumeric from 'autonumeric';
require('autonumeric')
import AutoNumeric from 'autonumeric/src/main';
require("channels")
import ApexCharts from 'apexcharts'
import createBulkActions from "../utils/bulk-actions"
require('../pages/commands')

window.ApexCharts = ApexCharts

// $(document).on('cocoon:after-insert', '#uploads', function(added_task) { console.log(added_task) })

$(document).on('cocoon:after-insert', '#uploads', function(e, insertedItem) {
  insertedItem.find('input[type=file]').fileinput();
});

$(document).ready(function () {
// $(document).on('turbolinks:load', function () {

  activateLeftAsideManager();
  activateSosGallery();
  activateDateRangePicker();
  activateSelect2Location();
  activateSelect2Ajax();
  openParentMenu();
  createBulkActions();
  activateScrollToAnchor();

  $(document).on('fileclear', 'input.file', function(event) {
    console.log(event);
    const $this = $(this);
    const $removeButton = $this.parent().parent().parent().parent();
    const $removeLink = $removeButton.find('a.remove_fields');
    $removeLink.click();
  });

  // TODO: fix translations
  const datepickerLocaleHu = {
    language: "hu"
  };

  // Bootstrap datepicker
  $('.date').datepicker({ ...datepickerLocaleHu,
    ...{daysOfWeekHighlighted: "0,6", calendarWeeks: true, todayHighlight: true }});
  // $(".datepicker").datepicker({ dateFormat:"yy-mm-dd", autoSize:true });

  // $(document).on('click', 'input.datetimepicker', function () {
  //     $(this).datetimepicker({
  //         addSliderAccess:true,
  //         sliderAccessArgs:{ touchonly:false }
  //     }).focus();
  // });

  $('.datetimepicker').datetimepicker(datepickerLocaleHu);

  // $('.datetimepicker').datetimepicker({
  //     changeMonth: true,
  //     changeYear: true
  // });

  // Selectize
  // $('select:not(.native)').selectize({});
  $('select:not(.native)').each(function () {
    let $this = $(this);
    $this.select2({language: "hu", placeholder: $this.attr('placeholder')});
  });

  // Bulk actions
  // $(document).on("click", "#list input.toggle", function () {
  //   $("#list [name='bulk_ids[]']").prop("checked", $(this).is(":checked"));
  // });
  //
  // $(document).on("click", "#bulk_by_query_trigger", function () {
  //   $("#list [name='bulk_ids[]'],#list input.toggle").prop("checked", !$(this).hasClass('active'));
  // });

  //$(".bulkactions a").on("click", function (event) {
  //  var $this = $(this);
  //  if ($this.data('url'))
  //  {
  //      var form = $this.parents('form:first');
  //      form.attr('action', $this.data('url'))
  //  }
  //
  //});

  $(document).on("click", ".bulkactions a", function (event) {
    event.preventDefault();

    // if ($('#bulk_by_query_trigger').hasClass('active') && $(this).attr('id') != 'bulk_popup')
    if ($('#item-all-by-query').val() == 1 && $(this).attr('id') != 'bulk_popup')
    {
      const url = $(this).attr('href');
      $('.bulkactionform').each(function () {
        const form = $(this);

        if (!form.is("[originalAction]")) {
          form.attr('originalAction', form.attr('action'));
          form.attr('originalMethod', form.attr('method'));
        }

        form.attr('action', url);
        form.attr('method', 'post');

        const bbq = document.createElement("input");
        bbq.name = "bulk_by_query";
        bbq.type = "hidden";
        bbq.value = 1;
        form.append(bbq);
        form.submit();
        form.attr('action', form.attr('originalAction'));
        form.attr('method', form.attr('originalMethod'));
      });
    }
    else if ($("[name='bulk_ids[]']:checked").length > 0) {
      const url = $(this).attr('href');
      if ($(this).attr('id') == 'bulk_popup') {
        let numWindows = 0;
        $("[name='bulk_ids[]']:checked").each(function() {
          numWindows += 1;
          if(numWindows>10) return false;
          const fire_url = url + '/' + $(this).val();
          setTimeout(function(){window.open(fire_url)}, 100 * numWindows);
        })
      }
      else {
        $('.bulkactionform').each(function () {
          const form = $(this);

          if (!form.is("[originalAction]")) {
            form.attr('originalAction', form.attr('action'));
            form.attr('originalMethod', form.attr('method'));
          }

          form.attr('action', url);
          form.attr('method', 'post');

          form.submit();

          form.attr('action', form.attr('originalAction'));
          form.attr('method', form.attr('originalMethod'));
        });
      }
    }
    else {
      alert('Kérem, jelöljön ki tételeket');
    }
  });

  $("#pc").on('change', function(){
    var form = $(this).parents('form:first');
    form.submit();
  });

  $(".pc a").on('click', function(){
    var pc = $("#pc");
    pc.val($(this).data('attribute'));
    var form = $(this).parents('form:first');
    form.submit();
  });

  $("#location_auto_name").on('click', function(){
    var $this = $(this);
    $('#location_name').prop('disabled', $this.is(':checked'));
  });

  // Money input masking
  // $('input.mask_thousands').each(function() {
  //   var $this = $(this);
  //   $this.autoNumeric({aSep: ' ', aDec: ',', aPad: false, aForm: false, wEmpty: 'empty'});
  //   // Formazas oldalbetolteskor
  //   if($this.attr('value') > 0) {
  //     $this.autoNumericSet($this.autoNumericGet())
  //   }
  // });
  // The options are...optional :)
  const autoNumericOptionsEuro = {
    digitGroupSeparator        : '.',
    decimalCharacter           : ',',
    decimalCharacterAlternative: '.',
    currencySymbol             : '',
    currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.suffix,
    roundingMethod             : AutoNumeric.options.roundingMethod.halfUpSymmetric,
    decimalPlaces              : 0
  };
  $('input.mask_thousands').each(function() {
    var $this = $(this);
    // $this.autoNumeric({aSep: ' ', aDec: ',', aPad: false, aForm: false, wEmpty: 'empty'});
    new AutoNumeric(this, autoNumericOptionsEuro);
    // Formazas oldalbetolteskor
    // if($this.attr('value') > 0) {
    //   $this.autoNumericSet($this.attr('value'))
    // }
  });

  // Initialization


  // Modals
  $('a[data-toggle=modal]').on('click', function() {
    return $('.dropdown').removeClass('open');
  });

  // $('a[data-target=#modal]').on('click', function() {
  //     return $('.ajax-loader').show();
  // });

  $(document).on('click', '[data-dismiss=modal], .modal-scrollable', function() {
    return $('.modal-body-content').empty();
  });

  $(document).on('click', '#modal', function(e) {
    return e.stopPropagation();
  });
  $(document).on('hidden', '#modal', function () {
    $(this).removeData('modal');
    $('.modal-body').empty();
  });

  $('#modal').on('shown', function () {
    var h1 = $('.modal-body h1');
    if(h1)
    {
      $('.modal-title').text(h1.text());
    }
    $('.modal-body .page-header').hide();
    //$('.modal-body .form-actions').remove();
    $('#modal-submit').on('click', function(){
      var form = $('.modal-body form');
      if(form)
      {
        var url = form.attr('action'); // the script where you handle the form input.

        $.ajax({
          type: "POST",
          url: url,
          data: form.serialize(), // serializes the form's elements.
          success: function(data)
          {
            // alert(data); // show response from the php script.
            $('.modal-body').html(data);
          }
        });

        return false; // avoid to execute the actual submit of the form.
      }
    });
  });

});


function activateSosGallery() {
  // blueimp.Gallery(carouselLinks, {
  //   container: '#blueimp-image-carousel',
  //   carousel: true
  // })

  $(document).on('click', '.modal-select', function(e) {
    var checkbox = itemBulkIdCheckbox('#so-gallery');
    checkbox.prop("checked", !checkbox.prop( "checked"));
  });

  $('#so-gallery').on('open, slideend', function(event, index, slide) {
    var toggle = $('.modal-select');
    var checkbox = itemBulkIdCheckbox(this);
    if(checkbox.prop( "checked"))
      toggle.addClass('active');
    else
      toggle.removeClass('active');
  });

  $(document).on('click', '.modal-element-open', function(e) {
    var item = galleryCurrentItem('#so-gallery');
    var url = item.parents('tr').find('a.stored-object-show').attr('href');
    var win = window.open(url, '_blank');
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Broswer has blocked it
      alert('Please allow popups for this site');
    }
  });

  $(document).on('click', '.modal-element-open-original', function(e) {
    var item = galleryCurrentItem('#blueimp-gallery');

    var url = item.data('href-original');
    var win = window.open(url, '_blank');
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Broswer has blocked it
      alert('Please allow popups for this site');
    }
  });

}

function itemBulkIdCheckbox(gallerySelector) {
  const item = galleryCurrentItem(gallerySelector);
  return item.parents('tr').find("input[name='bulk_ids[]']").first();
}

function galleryCurrentItem(gallerySelector) {
  const gallery = $(gallerySelector).data('gallery');
  return $(gallery.list[gallery.getIndex()]);
}

const format = "YYYY.MM.DD. HH:mm:ss";

function activateDateRangePicker() {
  moment.locale('hu', {
    week : {
      dow : 1 // Monday is the first day of the week
    }
  });
  var start = moment().subtract(29, 'days');
  var end = moment();
  var dateRangePickers = $('input.daterange');

  dateRangePickers.daterangepicker({
    // startDate: start,
    // endDate: end,
    showDropdowns: true,
    autoUpdateInput: false,
    timePicker: true,
    timePicker24Hour: true,
    ranges: {
      'Ma': [moment().startOf('day'), moment().endOf('day')],
      'Tegnap': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      'Elmúlt 7 napban': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
      'Elmúlt 30 napban': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
      'Ebben a hónapban': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
      'Előző hónapban': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')]
    },
    locale: {
      separator: " - ",
      applyLabel: "Ok",
      cancelLabel: "Törlés",
      fromLabel: "Kezdet",
      toLabel: "Vég",
      customRangeLabel: "Egyéni",
      weekLabel: "H",
      daysOfWeek: ["V", "H", "K", "Sze", "Cs", "P", "Szo"],
      monthNames: ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"],
      weekStart: 1,
      format: "YYYY.MM.DD. HH:mm:ss"
    }

  }, cb);

  cb(start, end);

  dateRangePickers.on('apply.daterangepicker', function(ev, picker) {
    var $this = $(this);
    $(this).val(picker.startDate.format(format) + ' - ' + picker.endDate.format(format));
    setStartEndHiddenFieldsForDaterange(this);
    // var name = $(this).attr('name');
    // var isNameHash = name.indexOf(']') > 0;
    // var name_gt = isNameHash ? name.replace(']','_gteq]') : name + '_gteq';
    // var name_lt = isNameHash ? name.replace(']','_lteq]') : name + '_lteq';
    // var input_gt = $("input[name='" + name_gt + "']");
    // var input_lt = $("input[name='" + name_lt + "']");
    //
    // var start_at = picker.startDate.format(format);
    // var end_at = picker.endDate.format(format);
    //
    // if (start_at.indexOf(':') === -1) start_at += ' 00:00:00';
    // if (end_at.indexOf(':') === -1) end_at += ' 23:59:59';
    //
    // if(input_gt.length === 0) {
    //   input_gt = $('<input>').attr({type: 'hidden', name: name_gt}).insertAfter($this);
    // }
    //
    // input_gt.val(start_at);
    //
    // if(input_lt.length === 0) {
    //   input_lt = $('<input>').attr({type: 'hidden', name: name_lt}).insertAfter($this);
    // }
    //
    // input_lt.val(end_at);
  });

  dateRangePickers.on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  dateRangePickers.each(function(i, element) {
    if($(element).val().length > 0) setStartEndHiddenFieldsForDaterange(element);
  });

  // $('.location-autocomplete').select2({
  //   ajax: {
  //     url: "/locations/autocomplete",
  //     data: function (params) {
  //       var query = {
  //         q: {name_or_location_path_human_cont: params.term},
  //         page: params.page || 1
  //       };
  //
  //       // Query parameters will be ?search=[term]&page=[page]
  //       return query;
  //     }
  //   }
  // });

}

function cb(start, end) {
  $('input.daterange span').html(start.format(format) + ' - ' + end.format(format));
}

function setStartEndHiddenFieldsForDaterange(el) {
  var $this = $(el);
  var name = $this.attr('name');
  var isNameHash = name.indexOf(']') > 0;
  var name_gt = isNameHash ? name.replace(']','_gteq]') : name + '_gteq';
  var name_lt = isNameHash ? name.replace(']','_lteq]') : name + '_lteq';
  var input_gt = $("input[name='" + name_gt + "']");
  var input_lt = $("input[name='" + name_lt + "']");
  var dateRangeArray = $this.val().split('-'),
    start_at = $.trim(dateRangeArray[0]), end_at = $.trim(dateRangeArray[1]);
  // var start_at = picker.startDate.format(format);
  // var end_at = picker.endDate.format(format);
  if (dateRangeArray.length == 0) return;

  if (start_at.indexOf(':') === -1) start_at += ' 00:00:00';
  if (end_at.indexOf(':') === -1) end_at += ' 23:59:59';

  if(input_gt.length === 0) {
    input_gt = $('<input>').attr({type: 'hidden', name: name_gt}).insertAfter($this);
  }

  input_gt.val(start_at);

  if(input_lt.length === 0) {
    input_lt = $('<input>').attr({type: 'hidden', name: name_lt}).insertAfter($this);
  }

  input_lt.val(end_at);
}

// imageFactoryRaktaroid
var imageFactoryRaktaroid = function (obj, callback) {
  var that = this;
  var img = this.imagePrototype.cloneNode(false);
  var url = obj;
  var backgroundSize = this.options.stretchImages;
  var called;
  var element;
  var title;
  function callbackWrapper (event) {
    if (!called) {
      event = {
        type: event.type,
        target: element
      };

      if (!element.parentNode) {
        // Fix for IE7 firing the load event for
        // cached images before the element could
        // be added to the DOM:
        return that.setTimeout(callbackWrapper, [event])
      };
      called = true;
      $(img).off('load error', callbackWrapper);

      if (backgroundSize) {
        if (event.type === 'load') {
          element.style.background = 'url("' + url +
            '") center no-repeat';
          element.style.backgroundSize = backgroundSize
        }
      }
      callback(event)
    }
  }
  if (typeof url !== 'string') {
    url = this.getItemProperty(obj, this.options.urlProperty);
    title = this.getItemProperty(obj, this.options.titleProperty)
  }
  if (backgroundSize === true) {
    backgroundSize = 'contain'
  }
  backgroundSize = this.support.backgroundSize &&
    this.support.backgroundSize[backgroundSize] && backgroundSize;
  if (backgroundSize) {
    element = this.elementPrototype.cloneNode(false);
  } else {
    element = img;
    img.draggable = false;
  }
  if (title) {
    element.title = title;
  }
  $(img).on('load error', callbackWrapper);
  img.src = url;

  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  xhr.onload = function(e) {
    var ori = 0;
    if (this.status == 200) {
      var blob = this.response;
      loadImage.parseMetaData(blob, function(data) {
        if (data.exif) {
          ori = data.exif.get('Orientation');
        }
        loadImage(
          blob,
          function(imgTag) {
            // element = imgTag;
            $(img).replaceWith(imgTag);
          }, {
            canvas: true,
            orientation: ori
          }
        );
      });
    }
  };
  xhr.send();

  return element
};


const activateSelect2Location = function () {
  $('.location-autocomplete').each(function(i, element) {
    const $this = $(element);
    const firstOption = $this.first();
    const placeholder = firstOption.text();

    $this.select2({
      language: "hu",
      ajax: {
        url: "/locations/autocomplete",
        data: function (params) {
          const query = {
            q: {name_or_location_path_human_cont: params.term},
            page: params.page || 1
          };

          // Query parameters will be ?search=[term]&page=[page]
          return query;
        }
      },
      placeholder: placeholder
    });
  });
};


// select2 autocomplete start
const activateSelect2Ajax = function () {
  $('.select2-autocomplete').each(function(i, element) {
    const $this = $(element);
    const url = $this.data("url");
    const qParam = $this.data("q-param");
    const autoSubmit = $this.data("auto-submit");
    const placeholder = $this.attr("placeholder");
    const name = $this.attr("name");
    const dropdown = $("<select name='" + $this.attr('name') + "' id='" + $this.attr('id') +
                        "' class='" + $this.attr('class') + "' " +
                        "data-url='" + url + "' " +
                        "placeholder='" + placeholder + "' " +
                        "data-q-param='" + qParam + "'" +
                        ">" +
                        "<option value='" + $this.val() + "'>" + $this.val() +
                        "</option></select>");

    $this.replaceWith(dropdown);

    dropdown.select2({
      language: "hu",
      ajax: {
        url: function (){
          let params = removeEmpty(getQueryStringFromForm($(this).parents("form")));
          delete params[name];

          return url + "?" + jQuery.param(params)
        },
        data: function (params) {
          const query = {
            q_filter: { [qParam]: params.term },
            page: params.page || 1
          };
          // Query parameters will be ?search=[term]&page=[page]
          return query;
        },
        processResults: function (data) {
          const textObjects = data.results.map(x => {
            let text_value = (x.text || "").toString();
            return {id: text_value, text: text_value}
          });
          return {...data, ...{results: textObjects} }
        },
      },
      placeholder: placeholder || "",
      dropdownAutoWidth: true,
      tags: true,
      allowClear: true
    });
    dropdown.next(".select2-container").addClass("input-group-sm input-sm form-control-sm p-0");

    if (autoSubmit) {
      dropdown.on('change', function (e) {
        dropdown.parents('form:first').submit();
      });
    }
  });
};

const openParentMenu = () => {
  $('.menu-item-active').parents('.menu-item-submenu').addClass('menu-item-open');
};

const activateScrollToAnchor = () => {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      // const yOffset = -150; //this.closest(".content").top;
      const yOffset = -1 * this.closest(".content").getBoundingClientRect().top - 10;
      const element = document.querySelector(this.getAttribute('href'));
      const form_group = element.closest(".form-group");
      const y = form_group.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    });
  });
};

const activateLeftAsideManager = () => {
  const mainNode = document.body;
  function callback(mutationsList, observer) {
    mutationsList.forEach(mutation => {
      if (mutation.attributeName === 'class') {
        localStorage.setItem("aside_minimized", mainNode.classList.contains('aside-minimize').toString());
      }
    })
  }

  const mutationObserver = new MutationObserver(callback)
  mutationObserver.observe(mainNode, { attributes: true })
};

const removeEmpty = obj => {
   Object.keys(obj).forEach(key => obj[key] == "" && delete obj[key]);
   return obj;
};

const renameQ = (obj, newKey) => {
  let re = /q\[.*\]/;
  Object.keys(obj).forEach(key => {
    if (re.test(key)) {
      obj[key.replace("q[", newKey + "[")] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

const getQueryString = () => {
  return decodeURIComponent(document.location.search.replace(/\+/g, '%20'))
    .replace(/(^\?)/,'').split("&")
    .map(function(n){return n = n.split("="),this[n[0]] = n[1],this}.bind({}))[0];
};

const getQueryStringFromForm = form => {
  return decodeURIComponent(form.serialize().replace(/\+/g, '%20'))
    .replace(/(^\?)/,'').split("&")
    .map(function(n){return n = n.split("="),this[n[0]] = n[1],this}.bind({}))[0];
};
// end of select2 autocomplete

// $(document).on("turbolinks:before-cache", function() {
//   $('.select2-hidden-accessible').select2('destroy');
// });

import "controllers"

// $(document).on('turbolinks:load', function () {
//   console.log('turbolinks:load event fired');
// });

// document.addEventListener('DOMContentLoaded', (event) => {
//   console.log('DOM fully loaded and parsed');
// });
