import consumer from "./consumer"
import CableReady from "cable_ready";

document.addEventListener('DOMContentLoaded', (event) => {
  const widgets = document.getElementsByClassName("dashboardSmartBoxWidget");

  for (let [_, widget] of Object.entries(widgets)) {
    console.log(widget.dataset.id);
    consumer.subscriptions.create({
      channel: "DashboardSmartBoxWidgetChannel",
      smart_box_id: widget.dataset.id
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to the dashboard smart box widget!");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log(data);
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }
});
