const createBulkActions = () => {
  // Bulk actions
  $("#item-all").on("click", (event) => {
    const cbx = $(event.target);

    $("input[name='bulk_ids[]']").prop("checked", cbx.is(":checked"));

    if (cbx.is(":checked")) {
      activateBulkActions();
      activateBulkByQueryWrapper();
    } else {
      clearBulkByQuery();
      clearBulk();
    }
  });

  $("input[name='bulk_ids[]']").on("click", (event) => {
    const cbx = $(event.target);
    activateBulkActions();

    if ($("input[name='bulk_ids[]']").length === $("input[name='bulk_ids[]']:checked").length) {
      $("#item-all").prop("checked", true);
      activateBulkByQueryWrapper();
    } else {
      $("#item-all").prop("checked", false);
      clearBulkByQuery();

      if ($("input[name='bulk_ids[]']:checked").length === 0) {
        clearBulk();
      }
    }

    if (cbx.is(":checked")) {

    } else {
    }
  });

  $("#item-all-by-query-link").on("click", (event) => {
    event.preventDefault();
    $("input[name='bulk_ids[]'],#item-all").prop("checked", true);
    $("#item-all-by-query").val(1);
    $("#select-all-by-query-question-wrapper").addClass("d-none");
    $("#select-all-by-query-active-wrapper").removeClass("d-none");
  });

  $("#item-none-by-query-link").on("click", (event) => {
    clearBulkByQuery();
    clearBulk();
  });
};

const activateBulkActions = () => {
  $("#bulk-actions").removeClass("d-none");
  $('#selected-items-count').text($("input[name='bulk_ids[]']:checked").length);
};

const activateBulkByQueryWrapper = () => {
  if (itemCount() <= $("input[name='bulk_ids[]']").length) return;

  $("#item-all-by-query-wrapper").removeClass("d-none");
  $("#item-all-by-query-wrapper").addClass("d-flex");
};

const clearBulkByQuery = () => {
  $("#item-all-by-query").val("");
  $("#item-all-by-query-wrapper").removeClass("d-none");
  $("#item-all-by-query-wrapper").addClass("d-flex");
  $("#select-all-by-query-question-wrapper").removeClass("d-none");
  $("#select-all-by-query-active-wrapper").addClass("d-none");
};

const clearBulk = () => {
  $("input[name='bulk_ids[]']").prop("checked", false);
  $("#item-all").prop("checked", false);
  $("#bulk-actions").addClass("d-none");
};

const itemCount = () => {
  return $("#bulk-actions").data("count");
};

export default function () {
  createBulkActions();
}
