$(document).ready(function () {
  showDescriptionBySelectedCommand();
});

const showDescriptionBySelectedCommand = () => {
  $(document).on('change', '#command_name', (e) => {
    const targetSelector = "#" + $('#command_name').val();
    $(".collapse").hide();
    $(targetSelector).show();
  })
};
